import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ian/source/repos/oconnorit/src/components/projectTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shared Youtube music experience with friends, in browser. Backend written in Go
using `}<a parentName="p" {...{
        "href": "https://developers.google.com/youtube/"
      }}>{`Google Youtube APIs`}</a>{`. Frontend
written with Bootstrap and `}<a parentName="p" {...{
        "href": "http://bourbon.io/"
      }}>{`Bourbon`}</a>{`, a lightweight library
for Sass.`}</p>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Highly scalable`}</li>
      <li parentName="ul">{`Synced timestamps of current song across all listeners`}</li>
      <li parentName="ul">{`Reactive interface updates live as songs are added, removed, or skipped`}</li>
      <li parentName="ul">{`User authentication backend built with an SQL database`}
        <ul parentName="li">
          <li parentName="ul">{`Passwords hashes stored using
`}<a parentName="li" {...{
              "href": "https://godoc.org/golang.org/x/crypto/bcrypt"
            }}>{`Go's implementation of bcrypt`}</a></li>
          <li parentName="ul">{`Secure user sessions`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Built-in live streaming chat`}</li>
      <li parentName="ul">{`In browser!`}</li>
    </ul>
    <img className="image fit" src="http://i.imgur.com/RNbo6Ef.jpg" alt="Table loaded in Chrome" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      